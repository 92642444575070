/* eslint-disable @typescript-eslint/no-unused-vars */
import NiceModal from '@ebay/nice-modal-react';
import { convertDateISOToDateStringFormat } from 'src/utils/convertDateISOToDateStringFormat';
import { DetailsDrawer } from 'src/ui/DetailsDrawer';
import useConvertDuration from 'src/utils/hooks/useConvertDuration';
import { convertItemSize } from 'src/utils/convertItemSize';
import { useTranslation } from 'react-i18next';
import MediaService from '../../services/MediaService';
export const useMediaDetails = ({ mediaCode }) => {
    const { convertDuration } = useConvertDuration();
    const { t, i18n } = useTranslation();
    const getMediaData = async (code) => {
        return MediaService.getMediaDataByMediaCode(code);
    };
    const showMediaDetails = async () => {
        const data = await getMediaData(mediaCode);
        const details = {
            type: 'media',
            size: convertItemSize(data.size || 0),
            durationLabel: t('details.media_total_time'),
            durationValue: convertDuration(data.duration),
            code: data.code,
            parentFolderName: data.defaultFolder
                ? t('details.default_folder')
                : data.folderName,
            createDate: convertDateISOToDateStringFormat(data.createDate, i18n.language),
            updateDate: convertDateISOToDateStringFormat(data.updateDate, i18n.language),
        };
        NiceModal.show(DetailsDrawer, {
            detailsItem: details,
        });
    };
    return { showMediaDetails };
};
