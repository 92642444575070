import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button } from 'src/ui/cosmosWeb/Button';
import { FontAwesomeIcon } from 'src/application/icons/fontAwesome';
import { faPlus, faTrash } from 'src/application/icons/fontAwesome/light';
import { secondsToTimeString } from 'src/utils/time';
import { useTranslation } from 'react-i18next';
import { Chapter } from './Chapter';
import { ChapterForm } from './ChapterForm';
import styles from './styles.module.scss';
import { useChapterList } from './hooks/useChapterList';
export function ChapterList({ mediaChapters, mediaDuration, onChaptersUpdate, onChapterClick, onDeleteChapters, }) {
    const { closeChapterForm, deleteAllChapters, deleteChapter, editingChapterIndex, errorMessage, isChapterBeingCreated, isChapterBeingEdited, saveChapter, startAddingNewChapter, startEditingChapter, } = useChapterList({ mediaChapters, mediaDuration, onChaptersUpdate });
    const { t } = useTranslation();
    const handleDeleteAllChapters = async () => {
        const isConfirmed = await onDeleteChapters();
        if (isConfirmed) {
            deleteAllChapters();
        }
    };
    return (_jsxs("div", { "data-testid": 'chapter-list', className: styles.chapterList, children: [_jsx("div", { className: styles.chapters, children: mediaChapters.map((chapter, index) => isChapterBeingEdited(index) ? (_jsx(ChapterForm, { "data-testid": 'chapter-form-editing', onSave: saveChapter, initialState: {
                        title: chapter.title,
                        time: secondsToTimeString(chapter.startTime),
                    }, disableInputTime: index === 0, onCancel: closeChapterForm, errorMessage: errorMessage }, chapter.startTime)) : (_jsx(Chapter, { time: chapter.startTime, title: chapter.title, ...(index !== 0 && {
                        onDelete: () => deleteChapter(chapter.startTime),
                    }), onEdit: () => startEditingChapter(index), onClick: () => onChapterClick(chapter) }, chapter.startTime))) }), editingChapterIndex === null && !isChapterBeingCreated && (_jsxs("div", { className: styles.buttons, children: [_jsxs(Button, { "data-testid": 'chapter-list-delete-all-btn', onClick: handleDeleteAllChapters, size: 'small', variation: 'secondary', children: [_jsx(FontAwesomeIcon, { icon: faTrash }), t('library.settings.chapters.buttons.delete_all')] }), _jsxs(Button, { "data-testid": 'chapter-list-new-chapter-btn', onClick: startAddingNewChapter, size: 'small', children: [_jsx(FontAwesomeIcon, { icon: faPlus }), t('library.settings.chapters.buttons.new_chapter')] })] })), isChapterBeingCreated && (_jsx(ChapterForm, { "data-testid": 'chapter-form-creating', onSave: saveChapter, onCancel: closeChapterForm, errorMessage: errorMessage }))] }));
}
