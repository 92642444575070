import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMediaData } from 'src/domain/Media/hooks';
import { useParams } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { Heading } from 'src/ui/cosmosWeb/Heading';
import { useTranslation } from 'react-i18next';
import { Loading } from 'src/ui/cosmos/Loading';
import { useTrackingDataHubEvents } from 'src/application/hooks/useTrackingDataHubEvents';
import { Actions } from 'src/infrastructure/services/Tracking/interfaces/EventWithAction';
import { ability } from 'src/infrastructure/libs/CASL';
import { ChaptersLanguageSelect } from '../ChaptersLanguageSelect';
import { Chapters } from '../ChaptersContainer';
import styles from './styles.module.scss';
export function MediaChaptersContainer() {
    const { mediaCode } = useParams();
    const { t } = useTranslation();
    const { trackingEventDebounced } = useTrackingDataHubEvents();
    const { data: media, isLoading: isLoadingMediaData } = useMediaData({
        mediaCode,
    });
    const [mediaDetail, setMediaDetail] = useState(null);
    const pageLoaded = useRef(false);
    useEffect(() => {
        if (!pageLoaded.current) {
            pageLoaded.current = true;
            trackingEventDebounced({
                action: Actions.VIEW_CHAPTER_PAGE,
                hasAccess: ability.can('edit', 'Media'),
            });
        }
    }, [trackingEventDebounced]);
    useEffect(() => {
        setMediaDetail(media);
    }, [media]);
    const onSetLanguage = (language) => {
        setMediaDetail((prev) => ({
            ...prev,
            language,
        }));
    };
    if (isLoadingMediaData) {
        return _jsx(Loading, { full: true });
    }
    return (_jsxs("div", { className: styles.chaptersContainer, children: [_jsx("div", { className: styles.header, children: _jsx(Heading, { size: 'small', children: t('library.settings.chapters.title') }) }), !mediaDetail?.language ? (_jsx(ChaptersLanguageSelect, { onSelectLanguage: onSetLanguage })) : (_jsx(Chapters, { media: mediaDetail }))] }));
}
