import { createPortal } from 'react-dom';
import { ID_MEDIA_SETTINGS_PORTAL } from './constants';
export const useCreatePortal = () => {
    const renderComponentAtPortal = (ui) => {
        const portalRoot = document.getElementById(ID_MEDIA_SETTINGS_PORTAL);
        return portalRoot ? createPortal(ui, portalRoot) : null;
    };
    return {
        renderComponentAtPortal,
    };
};
