import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Select, SelectGroup, SelectOption } from 'src/ui/cosmos/Select';
import { useTranslation, Trans } from 'react-i18next';
import { Loading } from 'src/ui/cosmos/Loading';
import { Alert } from 'src/ui/cosmos/Alert';
import { FontAwesomeIcon, faCircleInfo, } from 'src/application/icons/fontAwesome/light';
import { useState } from 'react';
import { Button } from 'src/ui/cosmosWeb/Button';
import { useCreatePortal } from 'src/domain/Media/hooks/useCreatePortal';
import { useListSupportedLanguages } from '../../../Shared/hooks/useListSupportedLanguages';
import styles from './styles.module.scss';
export function ChaptersLanguageSelect({ onSelectLanguage, }) {
    const { renderComponentAtPortal } = useCreatePortal();
    const { t } = useTranslation();
    const { allSupportedLanguages } = useListSupportedLanguages();
    const [language, setLanguage] = useState('');
    const handleSelectLanguage = (lang) => {
        setLanguage(lang);
    };
    if (allSupportedLanguages.isLoading) {
        return _jsx(Loading, { full: true });
    }
    return (_jsxs(_Fragment, { children: [_jsx("span", { className: styles.description, children: _jsx(Trans, { i18nKey: 'library.settings.chapters.select_language_description', components: { strong: _jsx("strong", {}) } }) }), _jsx("label", { className: '_d-block', htmlFor: 'source-language', children: t('library.settings.chapters.select_language_label') }), _jsx(Select, { id: 'source-language', className: styles.languageSelect, onChange: (event) => handleSelectLanguage(event.detail.value), placeholder: t('additional_content.modal.subtitle.form.label_source_language'), children: _jsx(SelectGroup, { children: allSupportedLanguages?.data?.map((language) => (_jsx(SelectOption, { value: language, children: t(`subtitle.supported_languages.${language}`) }, language))) }) }), _jsxs(Alert, { className: `hot-alert--info ${styles.alert}`, children: [_jsx(FontAwesomeIcon, { icon: faCircleInfo, size: 'lg' }), t('library.settings.chapters.select_language_alert')] }), renderComponentAtPortal(_jsx(Button, { disabled: !language, "data-testid": 'chapters-button-next', onClick: () => onSelectLanguage(language), children: t('library.settings.chapters.buttons.next') }))] }));
}
