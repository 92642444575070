import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button } from 'src/ui/cosmosWeb/Button';
import { Heading } from 'src/ui/cosmosWeb/Heading';
import { Modal } from 'src/ui/cosmosWeb/Modal';
import { useTranslation } from 'react-i18next';
import EventPrepAndTrackService from 'src/infrastructure/services/Tracking/EventPrepAndTrackService';
import { IntentEvent } from 'src/infrastructure/services/Tracking/interfaces/IntentEvent';
import { NiceModal, useModal } from 'src/infrastructure/libs/NiceModal';
import { useQueryClient } from 'src/application/hooks/useRequest';
import { QueryKeys } from 'src/infrastructure/libs/ReactQuery/enums';
import { useTrackingDataHubEvents } from 'src/application/hooks/useTrackingDataHubEvents';
import { Actions } from 'src/infrastructure/services/Tracking/interfaces/EventWithAction';
import { useEffect, useRef } from 'react';
import styles from './styles.module.scss';
export const ChaptersBatchFeedbackModal = NiceModal.create(({ image, title, description, buttonLabel, retryFn }) => {
    const modal = useModal();
    const { t } = useTranslation();
    const queryClient = useQueryClient();
    const isModalLoaded = useRef(false);
    const isModalError = typeof retryFn === 'function';
    const { trackingEventDebounced } = useTrackingDataHubEvents();
    useEffect(() => {
        if (!isModalLoaded.current && isModalError) {
            isModalLoaded.current = true;
            trackingEventDebounced({
                action: Actions.VIEW_CHAPTER_ERROR_MODAL,
            });
        }
    }, [trackingEventDebounced, isModalError]);
    const handleConfirmButtonClick = () => {
        queryClient.invalidateQueries({
            queryKey: [QueryKeys.CHAPTERS_BATCH_GENERATION_STATUS],
        });
        modal.hide();
    };
    const handleRetryButtonClick = () => {
        retryFn();
        trackingEventDebounced({
            action: Actions.CLICK_CHAPTER_ERROR_MODAL_RETRY,
        });
        modal.hide();
    };
    const onCloseModal = () => {
        if (isModalError) {
            trackingEventDebounced({
                action: Actions.CLOSE_CHAPTER_ERROR_MODAL,
            });
        }
        modal.remove();
    };
    return (_jsxs(Modal, { onCloseModal: onCloseModal, opened: modal.visible, "data-testid": 'basic-alert-modal', dismissible: false, className: styles.modal, children: [_jsx("div", { slot: 'heading', className: styles.heading, children: _jsx("img", { src: image, alt: '' }) }), _jsxs("div", { slot: 'description', className: styles.content, children: [_jsx(Heading, { size: 'small', className: styles.title, children: title }), _jsx("p", { className: styles.description, children: description })] }), _jsx("div", { slot: 'controls', className: styles.controls, children: retryFn ? (_jsxs(_Fragment, { children: [_jsx(Button, { "data-testid": 'modal-feedback-batch-close-button', variation: 'tertiary', className: styles.closeButton, onClick: () => {
                                EventPrepAndTrackService.sendDebouncedInteractClick(IntentEvent.ADDITIONAL_CONTENT_ERROR_CLOSE_CHAPTERS);
                                modal.hide();
                            }, children: t('additional_content.modal.chapters.error_modal.close') }), _jsx(Button, { "data-testid": 'modal-feedback-batch-retry-button', variation: 'primary', onClick: handleRetryButtonClick, className: styles.smallButton, children: buttonLabel })] })) : (_jsx(Button, { "data-testid": 'modal-feedback-batch-confirm-button', variation: 'primary', onClick: handleConfirmButtonClick, className: styles.largeButton, children: buttonLabel })) })] }));
});
