import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useMemo } from 'react';
import NiceModal from '@ebay/nice-modal-react';
import { faFolderArrowUp, faLock, faTrashCan, faClosedCaptioning, faList, } from 'src/application/icons/fontAwesome/light';
import { IconButton } from 'src/ui/cosmosWeb/IconButton';
import { useDeleteItems } from 'src/domain/Folder/hooks';
import { Tooltip } from 'src/ui/cosmos/Tooltip';
import { MoveModal } from 'src/domain/Folder/containers/Modal/MoveModal';
import { isMedia } from 'src/domain/Media/utils/isMedia';
import { DomainManagerDrawer } from 'src/domain/Media/modules/Domain/components/DomainManagerDrawer';
import { Can } from 'src/infrastructure/libs/CASL/can';
import { useTranslation } from 'react-i18next';
import { MediaContentContainerModal, ActiveComponent, } from 'src/domain/Media/components/Modal/MediaContentContainer';
import EventPrepAndTrackService from 'src/infrastructure/services/Tracking/EventPrepAndTrackService';
import { IntentEvent } from 'src/infrastructure/services/Tracking/interfaces/IntentEvent';
import useAccounts from 'src/domain/User/Accounts/hooks/useAccounts';
import useFeatureToggle from 'src/application/hooks/useFeatureToggle';
import { FeatureToggle } from 'src/application/interfaces/FeatureToggle';
import { useTrackingDataHubEvents } from 'src/application/hooks/useTrackingDataHubEvents';
import { Actions } from 'src/infrastructure/services/Tracking/interfaces/EventWithAction';
import styles from './styles.module.scss';
const ActionsContainer = ({ selectedItems }) => {
    const { t } = useTranslation();
    const { trackingEventDebounced } = useTrackingDataHubEvents();
    const deleteItems = useDeleteItems();
    const { mediasSelected, foldersSelected } = selectedItems.reduce((previous, current) => {
        if (isMedia(current)) {
            previous.mediasSelected.push(current);
        }
        else {
            previous.foldersSelected.push(current);
        }
        return previous;
    }, {
        mediasSelected: [],
        foldersSelected: [],
    });
    const hasOnlyMediasSelected = mediasSelected.length > 0 && foldersSelected.length === 0;
    const hasAtLeastOneMediaOrFolderSelected = foldersSelected.length >= 1 || mediasSelected.length >= 1;
    const shouldShowDeleteAction = useMemo(() => {
        return hasAtLeastOneMediaOrFolderSelected;
    }, [hasAtLeastOneMediaOrFolderSelected]);
    const shouldShowRestrictionAction = useMemo(() => {
        return hasOnlyMediasSelected;
    }, [hasOnlyMediasSelected]);
    const shouldShowSubtitleBatchAction = hasOnlyMediasSelected;
    const { currentAccount } = useAccounts();
    const { isEnabled } = useFeatureToggle({
        accountOwnerMarketplaceId: currentAccount.accountOwnerMarketplaceId,
    });
    const isChaptersBatchEnabled = isEnabled(FeatureToggle.HOTMART_PLAYER_ENABLE_ADDITIONAL_CONTENT_CHAPTERS_BATCH);
    const shouldShowChapterBatchAction = hasOnlyMediasSelected && isChaptersBatchEnabled;
    const handleDeleteItems = useCallback(() => {
        return deleteItems(selectedItems);
    }, [deleteItems, selectedItems]);
    return (_jsxs("div", { className: styles.container, children: [_jsx(Can, { I: 'edit', a: 'Media', children: _jsx(Tooltip, { text: t('general.move_to'), children: _jsx(IconButton, { className: styles.button, "data-testid": 'actions-container-move', context: 'neutral', variation: 'tertiary', icon: faFolderArrowUp, onClick: () => NiceModal.show(MoveModal, {
                            itemsToMove: [...selectedItems],
                        }) }) }) }), shouldShowRestrictionAction && (_jsx(Can, { I: 'domain', a: 'Media', children: _jsx(Tooltip, { text: t('general.restrictions'), children: _jsx(IconButton, { className: styles.button, "data-testid": 'actions-container-restriction', context: 'neutral', variation: 'tertiary', icon: faLock, onClick: () => NiceModal.show(DomainManagerDrawer, {
                            medias: selectedItems,
                        }) }) }) })), shouldShowSubtitleBatchAction && (_jsx(Can, { I: 'edit', a: 'Media', children: _jsx(Tooltip, { text: t('general.subtitle'), children: _jsx(IconButton, { className: styles.button, "data-testid": 'actions-media-content-container-subtitle', context: 'neutral', variation: 'tertiary', icon: faClosedCaptioning, onClick: () => {
                            NiceModal.show(MediaContentContainerModal, {
                                medias: selectedItems,
                                view: ActiveComponent.SUBTITLE,
                            });
                            EventPrepAndTrackService.sendDebouncedInteractClick(IntentEvent.ADDITIONAL_CONTENT_OPEN_SUBTITLES, 'button', [
                                {
                                    key: 'mediaCodes',
                                    value: selectedItems.length.toString(),
                                },
                            ]);
                        } }) }) })), shouldShowChapterBatchAction && (_jsx(Can, { I: 'edit', a: 'Media', children: _jsx(Tooltip, { text: t('additional_content.modal.chapters.header_title'), children: _jsx(IconButton, { className: styles.button, "data-testid": 'actions-media-content-container-chapter', context: 'neutral', variation: 'tertiary', icon: faList, onClick: () => {
                            NiceModal.show(MediaContentContainerModal, {
                                medias: selectedItems,
                                view: ActiveComponent.CHAPTERS,
                            });
                            trackingEventDebounced({
                                action: Actions.TRY_GENERATE_CHAPTER,
                            });
                        } }) }) })), shouldShowDeleteAction && (_jsx(Can, { I: 'delete', a: 'Media', children: _jsx(Tooltip, { text: t('general.remove'), children: _jsx(IconButton, { className: styles.button, "data-testid": 'actions-container-delete', onClick: handleDeleteItems, context: 'neutral', variation: 'tertiary', icon: faTrashCan }) }) }))] }));
};
export default ActionsContainer;
