import EventAgent from '@hotmart/event-agent-js';
import { isDevelopment, isProduction } from 'src/utils/env';
import { SYSTEM_NAME, EVENT_SCHEMA, ADMIN_ENTITY } from './constants';
EventAgent.mode = isProduction() ? 'production' : 'staging';
const mockEventAgent = (data) => console.info('datahub - ', data);
class DatahubProviderService {
    constructor() {
        if (isDevelopment()) {
            Object.assign(EventAgent, {
                send: mockEventAgent,
                sendMetrify: mockEventAgent,
            });
        }
    }
    sendInteractEvent({ userId, sessionId, planName, applicationVersion, pagePath, eventAction, eventDispatchedBy, intent, params, }) {
        EventAgent.send({
            system: SYSTEM_NAME,
            entity: EVENT_SCHEMA.interact.entity,
            action: EVENT_SCHEMA.interact.action,
            data_version: EVENT_SCHEMA.interact.version,
            event: {
                userId,
                sessionId,
                planName,
                applicationVersion,
                pagePath,
                eventAction,
                eventDispatchedBy,
                intent,
                params,
            },
        });
    }
    sendErrorEvent({ userId, sessionId, planName, applicationVersion, apiURL, message, pagePath, statusCode, stack, }) {
        EventAgent.send({
            system: SYSTEM_NAME,
            entity: EVENT_SCHEMA.error.entity,
            action: EVENT_SCHEMA.error.action,
            data_version: EVENT_SCHEMA.error.version,
            event: {
                userId,
                sessionId,
                planName,
                applicationVersion,
                apiURL,
                message,
                pagePath,
                statusCode,
                stack,
            },
        });
    }
    sendActionEvent({ action, event, data_version = '1.0', }) {
        EventAgent.send({
            system: SYSTEM_NAME,
            entity: ADMIN_ENTITY,
            action,
            data_version,
            event,
        });
    }
}
export default new DatahubProviderService();
