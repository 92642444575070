import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { faTrashAlt, faFolderArrowUp, faEdit, faDownload, faPlay, faShare, } from 'src/application/icons/fontAwesome/light';
import { FontAwesomeIcon } from 'src/application/icons/fontAwesome';
import { faEllipsisVertical, faInfoCircle, } from 'src/application/icons/fontAwesome/regular';
import { Dropdown, DropdownItem, DropdownHeader, } from 'src/ui/cosmosWeb/DropDown';
import EventPrepAndTrackService from 'src/infrastructure/services/Tracking/EventPrepAndTrackService';
import { IntentEvent } from 'src/infrastructure/services/Tracking/interfaces/IntentEvent';
import { IconButton } from 'src/ui/cosmosWeb/IconButton';
import { useMediaDownload, useMediaRename } from 'src/domain/Media/hooks';
import { BasicPromptModal } from 'src/ui/Modal/components/BasicPromptModal';
import { useMediaDetails } from 'src/domain/Media/hooks/useMediaDetails';
import { useDeleteItems } from 'src/domain/Folder/hooks';
import { MediaPreviewModal } from 'src/domain/Media/components/Modal/MediaPreview';
import { Can } from 'src/infrastructure/libs/CASL';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';
export const MediaControls = ({ onMove, media }) => {
    const renameMediaModalId = `rename-media-modal-${media.code}`;
    const renameMediaModal = useModal(renameMediaModalId);
    const { t } = useTranslation();
    const { renameMedia, isLoading } = useMediaRename();
    const { showMediaDetails } = useMediaDetails({ mediaCode: media.code });
    const deleteItems = useDeleteItems();
    const downloadMedia = useMediaDownload();
    const navigate = useNavigate();
    const location = useLocation();
    const onOpenMenuItem = () => {
        EventPrepAndTrackService.sendDebouncedInteractClick(IntentEvent.LIBRARY_ITEM_MENU_MEDIA);
    };
    const handleRenameMedia = async () => {
        EventPrepAndTrackService.sendDebouncedInteractClick(IntentEvent.LIBRARY_ITEM_MENU_RENAME_MEDIA);
        const newName = (await renameMediaModal.show());
        await renameMedia({ media, name: newName });
        renameMediaModal.hide();
        EventPrepAndTrackService.sendDebouncedInteractClick(IntentEvent.LIBRARY_RENAME_MEDIA);
    };
    return (_jsxs("div", { children: [_jsx(BasicPromptModal, { id: renameMediaModalId, title: t('general.rename'), value: media.name, loading: isLoading, buttons: {
                    save: { label: t('general.save') },
                    cancel: { label: t('general.cancel') },
                } }), _jsxs(Dropdown, { className: styles.dropDown, onOpenDropdown: onOpenMenuItem, docked: true, position: 'end', "data-testid": 'item-media-dropdown', children: [_jsx(IconButton, { type: 'button', slot: 'controls', variation: 'tertiary', context: 'neutral', size: 'small', icon: faEllipsisVertical, className: styles.dropDownButton }), _jsx(DropdownHeader, { slot: 'items', className: styles.dropdownHeaderItem, children: _jsx("div", { slot: 'label', children: t('library.header.more_options') }) }), _jsx(Can, { I: 'list', a: 'Media', children: _jsxs(DropdownItem, { "data-testid": 'media-controls-details', onClick: () => showMediaDetails(), slot: 'items', children: [_jsx("div", { slot: 'icon', className: styles.detailsIcon, children: _jsx(FontAwesomeIcon, { icon: faInfoCircle }) }), _jsx("div", { slot: 'label', children: t('general.details') })] }) }), _jsxs(DropdownItem, { slot: 'items', "data-testid": 'media-controls-preview', onClick: () => NiceModal.show(MediaPreviewModal, { media }), children: [_jsx("div", { slot: 'icon', children: _jsx(FontAwesomeIcon, { icon: faPlay }) }), _jsx("div", { slot: 'label', children: t('general.preview') })] }), _jsx(Can, { I: 'edit', a: 'Media', children: _jsxs(DropdownItem, { slot: 'items', "data-testid": 'media-controls-rename', onClick: handleRenameMedia, children: [_jsx("div", { slot: 'icon', children: _jsx(FontAwesomeIcon, { icon: faEdit }) }), _jsx("div", { slot: 'label', children: t('general.rename') })] }) }), _jsx(Can, { I: 'download', a: 'Media', children: _jsxs(DropdownItem, { onClick: () => {
                                downloadMedia(media.code);
                                EventPrepAndTrackService.sendDebouncedInteractClick(IntentEvent.LIBRARY_ITEM_MENU_DOWNLOAD_MEDIA);
                            }, "data-testid": 'media-controls-download', slot: 'items', children: [_jsx("div", { slot: 'icon', children: _jsx(FontAwesomeIcon, { icon: faDownload }) }), _jsx("div", { slot: 'label', children: t('general.download') })] }) }), _jsx(Can, { I: 'edit', a: 'Media', children: _jsxs(DropdownItem, { "data-testid": 'media-controls-move', onClick: () => {
                                onMove();
                                EventPrepAndTrackService.sendDebouncedInteractClick(IntentEvent.LIBRARY_ITEM_MENU_MOVE_MEDIA);
                            }, slot: 'items', children: [_jsx("div", { slot: 'icon', children: _jsx(FontAwesomeIcon, { icon: faFolderArrowUp }) }), _jsx("div", { slot: 'label', children: t('general.move_to') })] }) }), _jsx(Can, { I: 'embed', a: 'Media', children: _jsxs(DropdownItem, { "data-testid": 'media-controls-share', onClick: () => {
                                navigate(`/library/media/${media.code}/share`, {
                                    state: {
                                        referrer: `${location.pathname}${location.search}`,
                                    },
                                });
                                EventPrepAndTrackService.sendDebouncedInteractClick(IntentEvent.LIBRARY_ITEM_MENU_SHARE_MEDIA);
                            }, slot: 'items', children: [_jsx("div", { slot: 'icon', children: _jsx(FontAwesomeIcon, { icon: faShare }) }), _jsx("div", { slot: 'label', children: t('general.share') })] }) }), _jsx(Can, { I: 'delete', a: 'Media', children: _jsxs(DropdownItem, { "data-testid": 'media-controls-delete', onClick: () => {
                                deleteItems([media]);
                                EventPrepAndTrackService.sendDebouncedInteractClick(IntentEvent.LIBRARY_ITEM_MENU_DELETE_MEDIA);
                            }, slot: 'items', children: [_jsx("div", { slot: 'icon', children: _jsx(FontAwesomeIcon, { icon: faTrashAlt }) }), _jsx("div", { slot: 'label', children: t('general.exclude') })] }) })] })] }));
};
