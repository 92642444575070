import Api from 'src/infrastructure/services/Api';
const getChapterGenerationStatus = async () => {
    const response = await Api.call({
        method: 'GET',
        url: `${process.env.API_GATEWAY}/chapter/generate/status`,
    });
    return response.data;
};
const getChapterGenerationStatusByMedia = async ({ mediaCode, mediaLanguage, }) => {
    const response = await Api.call({
        method: 'GET',
        url: `${process.env.API_GATEWAY}/chapter/media/${mediaCode}/${mediaLanguage}/status`,
    });
    return response.data;
};
const generateChaptersWithAI = async ({ mediaCodes, language, }) => {
    const response = await Api.call({
        method: 'POST',
        url: `${process.env.API_GATEWAY}/chapter/generate`,
        data: { mediaCodes, language },
    });
    return response.data;
};
const retryFailedChapters = async (id) => {
    const response = await Api.call({
        method: 'POST',
        url: `${process.env.API_GATEWAY}/chapter/generate/${id}/reprocess`,
    });
    return response.data;
};
const getMediaChapters = async ({ mediaCode, mediaLanguage, }) => {
    const response = await Api.call({
        method: 'GET',
        url: `${process.env.API_CHAPTERS}/v1/media/${mediaCode}/${mediaLanguage}`,
    });
    return response.data;
};
const createOrUpdateMediaChapters = async ({ mediaCode, mediaLanguage, chapters, }) => {
    return await Api.call({
        method: 'PUT',
        url: `${process.env.API_GATEWAY}/chapter/media/${mediaCode}/${mediaLanguage}`,
        data: chapters,
    });
};
const deleteMediaChapters = async ({ mediaCode, mediaLanguage, }) => {
    return await Api.call({
        method: 'DELETE',
        url: `${process.env.API_GATEWAY}/chapter/media/${mediaCode}/${mediaLanguage}`,
    });
};
export { getChapterGenerationStatus, getChapterGenerationStatusByMedia, generateChaptersWithAI, retryFailedChapters, getMediaChapters, createOrUpdateMediaChapters, deleteMediaChapters, };
