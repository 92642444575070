import { useMutation, useQuery, useQueryClient, } from 'src/application/hooks/useRequest';
import ChaptersService from 'src/domain/Media/modules/Chapters/services/ChaptersService';
import { ChaptersStatus, } from 'src/infrastructure/endpoints/chapters/interfaces';
import { QueryKeys } from 'src/infrastructure/libs/ReactQuery/enums';
import ApiError from 'src/infrastructure/services/Api/ApiError';
const REFETCH_INTERVAL = 60000; // 60 seconds
export const useChaptersBatch = () => {
    const queryClient = useQueryClient();
    const generateChaptersBatch = useMutation({
        mutationFn: ({ mediaCodes, language }) => ChaptersService.generateChaptersWithAI({ mediaCodes, language }),
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: [QueryKeys.CHAPTERS_BATCH_GENERATION_STATUS],
            });
            queryClient.invalidateQueries({
                queryKey: [QueryKeys.MEDIA_CHAPTERS_STATUS],
            });
        },
    });
    const getChapterGenerationStatus = useQuery({
        queryKey: [QueryKeys.CHAPTERS_BATCH_GENERATION_STATUS],
        queryFn: async () => ChaptersService.getChapterGenerationStatus(),
        refetchInterval: (data) => {
            const areThereChaptersProcessing = data?.find((item) => item.status === ChaptersStatus.PROCESSING);
            if (areThereChaptersProcessing) {
                return REFETCH_INTERVAL;
            }
            return false;
        },
    });
    const retryFailedChapters = useMutation({
        mutationFn: (id) => ChaptersService.retryFailedChapters(id),
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: [QueryKeys.CHAPTERS_BATCH_GENERATION_STATUS],
            });
        },
        onError: (error) => {
            if (error instanceof ApiError) {
                error.notify(error.message);
            }
        },
    });
    return {
        generateChaptersBatch,
        getChapterGenerationStatus,
        retryFailedChapters,
    };
};
