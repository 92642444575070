import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FontAwesomeIcon, faPenLine, faTrash, } from 'src/application/icons/fontAwesome/light';
import { secondsToTimeString } from 'src/utils/time';
import { TextTruncate } from 'src/ui/TextTruncate';
import styles from './styles.module.scss';
export function Chapter({ time, title, onDelete, onEdit, onClick, }) {
    return (_jsxs("div", { "data-testid": 'chapter', className: styles.chapter, children: [_jsxs("div", { "data-testid": 'chapter-info', onClick: onClick, onKeyDown: (e) => {
                    if (e.key === 'Enter') {
                        onClick();
                    }
                }, className: styles.chapterInfo, children: [_jsx("time", { dateTime: secondsToTimeString(time), children: secondsToTimeString(time) }), _jsx("span", { children: _jsx(TextTruncate, { children: title }) })] }), _jsxs("div", { className: styles.actions, children: [onDelete && (_jsx("button", { "data-testid": 'chapter-delete-btn', onClick: onDelete, children: _jsx(FontAwesomeIcon, { icon: faTrash }) })), _jsx("button", { "data-testid": 'chapter-edit-btn', onClick: onEdit, children: _jsx(FontAwesomeIcon, { icon: faPenLine }) })] })] }));
}
