// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `span.MC8lJbqW1JPa4cob_mkk{font-size:var(--hc-size-text-small);margin-bottom:1.5rem}.dKiW_LbhhSTtiiPphVyb{max-width:21.875rem}.tk1UTa04WMvmtMuc2TAw{display:flex;max-width:30.875rem;gap:1rem;margin:2rem 0 0;font-size:var(--hc-size-text-small)}.tk1UTa04WMvmtMuc2TAw span{flex:1}`, "",{"version":3,"sources":["webpack://./src/domain/Media/modules/Chapters/containers/ChaptersLanguageSelect/styles.module.scss"],"names":[],"mappings":"AAAA,0BACE,mCAAA,CACA,oBAAA,CAGF,sBACE,mBAAA,CAGF,sBACE,YAAA,CACA,mBAAA,CACA,QAAA,CACA,eAAA,CACA,mCAAA,CAEA,2BACE,MAAA","sourcesContent":["span.description {\n  font-size: var(--hc-size-text-small);\n  margin-bottom: 1.5rem;\n}\n\n.languageSelect {\n  max-width: 21.875rem\n}\n\n.alert {\n  display: flex;\n  max-width: 30.875rem;\n  gap: 1rem;\n  margin: 2rem 0 0;\n  font-size: var(--hc-size-text-small);\n  \n  span {\n    flex: 1;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"description": `MC8lJbqW1JPa4cob_mkk`,
	"languageSelect": `dKiW_LbhhSTtiiPphVyb`,
	"alert": `tk1UTa04WMvmtMuc2TAw`
};
export default ___CSS_LOADER_EXPORT___;
