import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/* eslint-disable max-lines */
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { ChaptersStatus, } from 'src/infrastructure/endpoints/chapters/interfaces';
import { useTranslation } from 'react-i18next';
import { Player } from 'src/ui/Player';
import { usePlayerPostMessage } from 'src/application/hooks/usePlayerPostMessage';
import { NiceModal } from 'src/infrastructure/libs/NiceModal';
import BasicConfirmationModal from 'src/ui/Modal/components/BasicConfirmationModal';
import PlayerLoader from 'src/ui/cosmos/ContentLoader';
import { Alert } from 'src/ui/cosmos/Alert';
import { faMicrochipAi, FontAwesomeIcon, } from 'src/application/icons/fontAwesome/light';
import { useTrackingDataHubEvents } from 'src/application/hooks/useTrackingDataHubEvents';
import { Actions } from 'src/infrastructure/services/Tracking/interfaces/EventWithAction';
import { Tooltip } from 'src/ui/cosmos/Tooltip';
import { Button } from 'src/ui/cosmosWeb/Button';
import { useCreatePortal } from 'src/domain/Media/hooks/useCreatePortal';
import { useWarnIfUnsavedChanges } from 'src/application/hooks/useWarnIfUnsavedChanges';
import { ChapterList } from '../../components/ChapterList';
import { useMediaChapters } from '../../hooks/useMediaChapters';
import { ChapterListLoader } from '../../components/ChapterList/ChapterListLoader';
import { useChaptersBatch } from '../../hooks/useChaptersBatch';
import { useShowChaptersBatchFeedbackModal } from '../../hooks/useShowChaptersBatchFeedbackModal';
import styles from './styles.module.scss';
const chapterGeneratingStatuses = [
    ChaptersStatus.PROCESSING,
    ChaptersStatus.QUEUED,
];
export function Chapters({ media }) {
    const { trackingEventDebounced } = useTrackingDataHubEvents();
    const { renderComponentAtPortal } = useCreatePortal();
    const playerRef = useRef(null);
    const { setPlayerTimeInSeconds, updatePlayerChapters } = usePlayerPostMessage(playerRef);
    const INTRO_CHAPTER = useRef({
        startTime: 0,
        endTime: media.duration,
        title: 'Intro',
    });
    const [chapters, setChapters] = useState([INTRO_CHAPTER.current]);
    const chaptersCopy = useRef([]);
    const { t } = useTranslation();
    const { setSaveButtonState } = useOutletContext();
    const [isAIButtonDisabled, setIsAIButtonDisabled] = useState(true);
    const { mediaChaptersQuery, mediaChaptersStatus, createOrUpdateMediaChapters, deleteMediaChapters, } = useMediaChapters({
        mediaCode: media.code,
        mediaLanguage: media.language,
    });
    const isGeneratingChapters = chapterGeneratingStatuses.includes(mediaChaptersStatus?.data?.status);
    const chapterGeneratedByAI = !!mediaChaptersStatus?.data?.generatedByIa;
    const { generateChaptersBatch } = useChaptersBatch();
    const feedbackModal = useShowChaptersBatchFeedbackModal();
    const { data: mediaChapters, isLoading: isLoadingChapters } = mediaChaptersQuery;
    useEffect(() => {
        if (mediaChapters?.length > 0) {
            setChapters(mediaChapters);
            chaptersCopy.current = mediaChapters;
        }
    }, [mediaChapters]);
    // ***************************************************************************
    // This is needed to handle the setSaveButtonState button bug
    // which causes the component to render infinitely.
    const chaptersRef = useRef([]);
    const saveChaptersRef = useRef(async () => {
        await createOrUpdateMediaChapters.mutateAsync({
            chapters: chaptersRef.current,
        });
        trackingEventDebounced({
            action: Actions.CLICK_CHAPTER_PAGE_SAVE,
        });
    });
    useEffect(() => {
        const ensureIntroChapter = () => {
            if (chapters.length === 0) {
                chaptersCopy.current = [];
                setChapters([INTRO_CHAPTER.current]);
            }
            chaptersRef.current = chapters;
        };
        ensureIntroChapter();
        setIsAIButtonDisabled(chaptersCopy.current.length > 0 || !!isGeneratingChapters);
        updatePlayerChapters(chaptersCopy.current);
    }, [chapters, isGeneratingChapters, updatePlayerChapters]);
    // ***************************************************************************
    const hasUpdated = useMemo(() => JSON.stringify(chapters) !== JSON.stringify(chaptersCopy.current), [chapters]);
    useWarnIfUnsavedChanges({
        mustConfirmExitFromPage: hasUpdated,
        confirmation: async () => {
            const isConfirmed = await NiceModal.show(BasicConfirmationModal, {
                title: t `controls.modal.title`,
                description: t `controls.modal.description`,
                buttons: {
                    yes: {
                        title: t `controls.modal.buttons.yes`,
                    },
                    no: {
                        title: t `controls.modal.buttons.no`,
                        color: 'tertiary',
                        context: 'neutral',
                    },
                },
            });
            return !!isConfirmed;
        },
    });
    useEffect(() => {
        setSaveButtonState({
            show: true,
            enable: hasUpdated && !isGeneratingChapters,
            isLoading: createOrUpdateMediaChapters.isLoading,
            handleSave: saveChaptersRef.current,
        });
        return () => {
            setSaveButtonState({
                show: false,
                enable: false,
                isLoading: false,
                handleSave: undefined,
            });
        };
    }, [
        hasUpdated,
        createOrUpdateMediaChapters.isLoading,
        setSaveButtonState,
        isGeneratingChapters,
    ]);
    const confirmAIReplace = useCallback(async () => {
        if (!hasUpdated) {
            return true;
        }
        const isConfirmed = await NiceModal.show(BasicConfirmationModal, {
            title: t `library.settings.chapters.modals.generate_with_hotmart_ia.title`,
            description: t `library.settings.chapters.modals.generate_with_hotmart_ia.description`,
            buttons: {
                yes: {
                    title: t `library.settings.chapters.modals.generate_with_hotmart_ia.confirm`,
                },
                no: {
                    title: t `library.settings.chapters.modals.generate_with_hotmart_ia.cancel`,
                    color: 'tertiary',
                    context: 'neutral',
                },
            },
        });
        return !!isConfirmed;
    }, [hasUpdated, t]);
    const handleGenerateChaptersByAI = useCallback(async () => {
        const isConfirm = await confirmAIReplace();
        if (!isConfirm) {
            return;
        }
        trackingEventDebounced({
            action: Actions.CLICK_CHAPTER_GENERATE_AI,
        });
        try {
            await generateChaptersBatch.mutateAsync({
                mediaCodes: [media.code],
                language: media.language,
            });
            feedbackModal.showSuccessMessage();
        }
        catch {
            feedbackModal.showErrorMessage(handleGenerateChaptersByAI);
        }
    }, [
        confirmAIReplace,
        feedbackModal,
        generateChaptersBatch,
        media.code,
        media.language,
        trackingEventDebounced,
    ]);
    const handleDeleteAllChapters = async () => {
        const isConfirmed = await NiceModal.show(BasicConfirmationModal, {
            title: t `library.settings.chapters.modals.exclude_all.title`,
            description: t `library.settings.chapters.modals.exclude_all.description`,
            buttons: {
                yes: {
                    title: t `library.settings.chapters.modals.exclude_all.confirm`,
                },
                no: {
                    title: t `library.settings.chapters.modals.exclude_all.cancel`,
                    color: 'tertiary',
                    context: 'neutral',
                },
            },
        });
        if (isConfirmed) {
            await deleteMediaChapters.mutateAsync();
        }
        return isConfirmed;
    };
    return (_jsxs(_Fragment, { children: [_jsx("span", { className: styles.description, children: t('library.settings.chapters.description') }), chapterGeneratedByAI && (_jsxs(Alert, { className: `${styles.alert}`, dismissible: true, children: [_jsx(FontAwesomeIcon, { icon: faMicrochipAi, size: 'lg' }), t('library.settings.chapters.generate_with_hotmart_ai_alert')] })), _jsxs("div", { className: styles.content, "data-testid": 'chapters-container', children: [_jsx("div", { className: styles.player, children: isGeneratingChapters ? (_jsx(PlayerLoader, { className: styles.playerContentLoader, "data-testid": 'player-loader' })) : (_jsx(Player, { ref: playerRef, media: media, editMode: true })) }), isLoadingChapters || isGeneratingChapters ? (_jsx(ChapterListLoader, {})) : (_jsx(ChapterList, { mediaChapters: chapters, mediaDuration: media.duration, onChaptersUpdate: setChapters, onChapterClick: (chapter) => setPlayerTimeInSeconds(chapter.startTime), onDeleteChapters: handleDeleteAllChapters }))] }), renderComponentAtPortal(_jsx(Tooltip, { text: isAIButtonDisabled
                    ? t('library.settings.chapters.tooltips.generate_with_ai_again')
                    : '', children: _jsxs(Button, { onClick: handleGenerateChaptersByAI, "data-testid": 'generate-media-chapters-button', disabled: isAIButtonDisabled, variation: 'secondary', className: '_mr-2', children: [_jsx(FontAwesomeIcon, { icon: faMicrochipAi, size: 'lg' }), t('library.settings.chapters.buttons.generate_with_hotmart_ia')] }) }))] }));
}
