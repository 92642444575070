export const SYSTEM_NAME = 'hotmart_player';
export const EVENT_SCHEMA = {
    interact: {
        entity: 'interact',
        action: 'measure',
        version: '1.0',
    },
    error: {
        entity: 'error',
        action: 'measure',
        version: '1.0',
    },
};
export const ADMIN_ENTITY = 'admin';
