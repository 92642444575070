import { useCallback } from 'react';
import useAccounts from 'src/domain/User/Accounts/hooks/useAccounts';
import EventPrepAndTrackService from 'src/infrastructure/services/Tracking/EventPrepAndTrackService';
import { debounce } from 'src/utils/debounce';
const DEBOUNCE_TIME = 1000;
export const useTrackingDataHubEvents = () => {
    const { currentAccount } = useAccounts();
    const trackingEvent = useCallback(({ action, hasAccess = true }) => {
        EventPrepAndTrackService.sendInteractEventWithAction({
            action,
            hasAccess,
            userRole: currentAccount?.accountOwner ? 'OWNER' : 'COLLABORATOR',
        });
    }, [currentAccount?.accountOwner]);
    const trackingEventDebounced = debounce(trackingEvent, DEBOUNCE_TIME);
    return {
        trackingEventDebounced,
    };
};
