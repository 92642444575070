import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable max-lines */
import { useRef, useState, } from 'react';
import { FontAwesomeIcon, faChartSimple, faLockKeyhole, faPhotoFilm, faShare, faSubtitles, faPaintRoller, faListCheck, faArrowLeft, faList, } from 'src/application/icons/fontAwesome/light';
import { Collapse, CollapseItem } from 'src/ui/cosmosWeb/Collapse';
import EventPrepAndTrackService from 'src/infrastructure/services/Tracking/EventPrepAndTrackService';
import { IntentEvent } from 'src/infrastructure/services/Tracking/interfaces/IntentEvent';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Can, ability } from 'src/infrastructure/libs/CASL';
import { useMediaData } from 'src/domain/Media/hooks';
import { Button } from 'src/ui/cosmosWeb/Button';
import { Loading } from 'src/ui/cosmos/Loading';
import { ContentFeedback } from 'src/ui/ContentFeedback';
import contentErrorImg from 'public/assets/svg/content_error.svg';
import useFolderParents from 'src/domain/Folder/hooks/useFolderParents';
import { Breadcrumb } from 'src/application/Layout/Breadcrumb';
import { Tag } from 'src/ui/cosmosWeb/Tag';
import { FeatureToggle } from 'src/application/interfaces/FeatureToggle';
import useFeatureToggle from 'src/application/hooks/useFeatureToggle';
import useAccounts from 'src/domain/User/Accounts/hooks/useAccounts';
import { useTrackingDataHubEvents } from 'src/application/hooks/useTrackingDataHubEvents';
import { Actions } from 'src/infrastructure/services/Tracking/interfaces/EventWithAction';
import { ID_MEDIA_SETTINGS_PORTAL } from 'src/domain/Media/hooks/useCreatePortal/constants';
import { MenuItem } from './components/MenuItem';
import styles from './styles.module.scss';
import Header from './components/Header';
const MediaSettingsLayout = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const { trackingEventDebounced } = useTrackingDataHubEvents();
    const routeState = useRef(location.state);
    const { mediaCode } = useParams();
    const [isOpen, setIsOpen] = useState(true);
    const { data: media } = useMediaData({ mediaCode });
    const mediaFolderCode = media?.folderCode;
    const { data: foldersParents, isError, isLoading, } = useFolderParents({ code: mediaFolderCode });
    const [saveButtonState, setSaveButtonState] = useState({});
    const isActive = (path) => {
        return location.pathname === `/library/media/${mediaCode}/${path}`;
    };
    const handleGoBackToReferrer = () => {
        const referrer = routeState.current?.referrer || '/library';
        navigate(referrer);
    };
    const { currentAccount } = useAccounts();
    const { isEnabled } = useFeatureToggle({
        accountOwnerMarketplaceId: currentAccount.accountOwnerMarketplaceId,
    });
    const isChaptersEnabled = isEnabled(FeatureToggle.HOTMART_PLAYER_ENABLE_ADDITIONAL_CONTENT_CHAPTERS_BATCH);
    if (isLoading)
        return (_jsx("div", { className: styles.loadingContainer, children: _jsx(Loading, { full: true }) }));
    if (isError)
        return (_jsx(ContentFeedback, { title: t('general.load_content_error.title'), description: t('general.load_content_error.description'), svg: contentErrorImg }));
    const canShare = ability.can('embed', 'Media') || ability.can('share', 'Media');
    return (_jsxs("section", { className: styles.wrapper, children: [_jsx(Breadcrumb, { media: media, folders: foldersParents }), _jsxs("section", { className: styles.container, children: [_jsx(Header, { media: media }), _jsxs("section", { className: styles.content, children: [_jsxs("nav", { "data-testid": 'aside-menu', children: [_jsxs(Can, { I: 'edit', a: 'Media', children: [_jsxs(MenuItem, { to: `/library/media/${media.code}/analytics`, onClick: () => {
                                                    EventPrepAndTrackService.sendDebouncedInteractClick(IntentEvent.PAGE_ANALYTICS);
                                                }, active: isActive('analytics'), children: [_jsx(FontAwesomeIcon, { icon: faChartSimple }), _jsx("span", { className: styles.menuTitle, children: t('media.analytics') })] }), _jsx(Collapse, { onClick: () => setIsOpen(!isOpen), className: styles.collapse, children: _jsxs(CollapseItem, { active: isOpen, children: [_jsx("div", { slot: 'icon', className: styles.icon, children: _jsx(FontAwesomeIcon, { icon: faPaintRoller }) }), _jsx("div", { slot: 'title', children: t('general.customization') }), _jsxs("div", { slot: 'content', children: [_jsxs(MenuItem, { to: `/library/media/${media.code}/thumbnail`, active: isActive('thumbnail'), children: [_jsx(FontAwesomeIcon, { icon: faPhotoFilm }), _jsx("span", { className: styles.menuTitle, children: t('media.thumbnail') })] }), _jsxs(MenuItem, { to: `/library/media/${media.code}/controls`, active: isActive('controls'), children: [_jsx(FontAwesomeIcon, { icon: faListCheck }), _jsx("span", { className: styles.menuTitle, children: t('media.controls') })] })] })] }) }), isChaptersEnabled && (_jsxs(MenuItem, { "data-testid": 'menu-item-chapters', className: styles.chaptersMenuItem, to: `/library/media/${media.code}/chapters`, onClick: () => {
                                                    trackingEventDebounced({
                                                        action: Actions.CLICK_CHAPTER_SIDEMENU,
                                                    });
                                                }, active: isActive('chapters'), children: [_jsx(FontAwesomeIcon, { icon: faList }), _jsx("span", { className: styles.menuTitle, children: t('library.settings.chapters.title') }), _jsx(Tag, { className: styles.new_tag, size: 'medium', children: t('library.settings.chapters.tags.new') })] })), _jsxs(MenuItem, { to: `/library/media/${media.code}/subtitle`, active: isActive('subtitle'), children: [_jsx(FontAwesomeIcon, { icon: faSubtitles }), _jsx("span", { className: styles.menuTitle, children: t('media.subtitle') })] })] }), _jsx(Can, { I: 'domain', a: 'Media', children: _jsxs(MenuItem, { to: `/library/media/${media.code}/restriction`, active: isActive('restriction'), children: [_jsx(FontAwesomeIcon, { icon: faLockKeyhole }), _jsx("span", { className: styles.menuTitle, children: t('media.restriction') })] }) }), canShare && (_jsxs(MenuItem, { to: `/library/media/${media.code}/share`, active: isActive('share'), children: [_jsx(FontAwesomeIcon, { icon: faShare }), _jsx("span", { className: styles.menuTitle, children: t('media.share') })] }))] }), _jsx("div", { children: _jsx(Outlet, { context: {
                                        setSaveButtonState,
                                        media,
                                    } }) })] }), _jsx("footer", { className: styles.footer, children: _jsxs("div", { className: styles.footerContent, children: [_jsxs(Button, { variation: 'secondary', onClick: handleGoBackToReferrer, children: [_jsx("div", { slot: 'icon', children: _jsx(FontAwesomeIcon, { icon: faArrowLeft }) }), t('general.back')] }), _jsxs("div", { className: '_d-flex', children: [_jsx("div", { id: ID_MEDIA_SETTINGS_PORTAL }), saveButtonState?.show && (_jsx(Button, { loading: saveButtonState?.isLoading, disabled: !saveButtonState?.enable, onClick: saveButtonState?.handleSave, "data-testid": 'custom-media-save-button', children: t('general.save') }))] })] }) })] })] }));
};
export default MediaSettingsLayout;
