// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.WoCdr4xzMgUejXaCJN2E{display:flex;flex-direction:column}.WoCdr4xzMgUejXaCJN2E .wPgC81tsTg6EGPhDjgZL{--hc-h-small-lg-font-size: var(--hc-size-text-2extralarge)}`, "",{"version":3,"sources":["webpack://./src/domain/Media/modules/Chapters/containers/MediaChaptersContainer/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,qBAAA,CAEA,4CACE,0DAAA","sourcesContent":[".chaptersContainer {\n  display: flex;\n  flex-direction: column;\n\n  .header {\n    --hc-h-small-lg-font-size: var(--hc-size-text-2extralarge);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"chaptersContainer": `WoCdr4xzMgUejXaCJN2E`,
	"header": `wPgC81tsTg6EGPhDjgZL`
};
export default ___CSS_LOADER_EXPORT___;
