// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `span.S0fu0P3n6c6E2Vd9NLBx{font-size:var(--hc-size-text-small);margin-bottom:1.5rem}.xs9OJjjTNic82LiQKZwh{font-size:var(--hc-size-text-small)}.xs9OJjjTNic82LiQKZwh svg{margin-right:.5rem}.xs9OJjjTNic82LiQKZwh span{flex:1}.L7Fi0lIB8VirzckEGIpr{display:flex;gap:1.125rem}.L7Fi0lIB8VirzckEGIpr .lP5B7F_KM7KA4CgQo_Dj{display:flex;border-radius:.5rem;width:100%;height:15.625rem}.L7Fi0lIB8VirzckEGIpr .lP5B7F_KM7KA4CgQo_Dj iframe{border-radius:.5rem}.L7Fi0lIB8VirzckEGIpr .lP5B7F_KM7KA4CgQo_Dj .QbvQOujVYTtjmJ9YbpHA{width:100%;height:100%}`, "",{"version":3,"sources":["webpack://./src/domain/Media/modules/Chapters/containers/ChaptersContainer/styles.module.scss"],"names":[],"mappings":"AAAA,0BACE,mCAAA,CACA,oBAAA,CAGF,sBACE,mCAAA,CAEA,0BACE,kBAAA,CAGF,2BACE,MAAA,CAIJ,sBACE,YAAA,CACA,YAAA,CAEA,4CACE,YAAA,CACA,mBAAA,CACA,UAAA,CACA,gBAAA,CAEA,mDACE,mBAAA,CAGF,kEACE,UAAA,CACA,WAAA","sourcesContent":["span.description {\n  font-size: var(--hc-size-text-small);\n  margin-bottom: 1.5rem;\n}\n\n.alert {\n  font-size: var(--hc-size-text-small);\n\n  svg {\n    margin-right: 0.5rem;\n  }\n  \n  span {\n    flex: 1;\n  }\n}\n\n.content {\n  display: flex;\n  gap: 1.125rem;\n\n  .player {\n    display: flex;\n    border-radius: 0.5rem;\n    width: 100%;\n    height: 15.625rem;\n\n    iframe {\n      border-radius: 0.5rem;\n    }\n\n    .playerContentLoader {\n      width: 100%;\n      height: 100%;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"description": `S0fu0P3n6c6E2Vd9NLBx`,
	"alert": `xs9OJjjTNic82LiQKZwh`,
	"content": `L7Fi0lIB8VirzckEGIpr`,
	"player": `lP5B7F_KM7KA4CgQo_Dj`,
	"playerContentLoader": `QbvQOujVYTtjmJ9YbpHA`
};
export default ___CSS_LOADER_EXPORT___;
