import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTrackingDataHubEvents } from 'src/application/hooks/useTrackingDataHubEvents';
import { Actions } from 'src/infrastructure/services/Tracking/interfaces/EventWithAction';
import { TIME_ERROR_VALIDATOR_MESSAGE } from './constants';
export function useChapterList({ mediaChapters: chapters, mediaDuration, onChaptersUpdate: updateChapters, }) {
    const [isChapterBeingCreated, setIsChapterBeingCreated] = useState(false);
    const [editingChapterIndex, setEditingChapterIndex] = useState(null);
    const [errorMessage, setErrorMessage] = useState('');
    const { t } = useTranslation();
    const { trackingEventDebounced } = useTrackingDataHubEvents();
    const isChapterBeingEdited = (index) => editingChapterIndex === index;
    const startEditingChapter = (index) => {
        setEditingChapterIndex(index);
        setIsChapterBeingCreated(false);
        trackingEventDebounced({
            action: Actions.CLICK_CHAPTER_TIMEBAR_EDIT,
        });
    };
    const startAddingNewChapter = () => {
        setIsChapterBeingCreated(true);
        setEditingChapterIndex(null);
        trackingEventDebounced({
            action: Actions.CLICK_CHAPTER_ADD_NEW,
        });
    };
    const closeChapterForm = () => {
        setEditingChapterIndex(null);
        setIsChapterBeingCreated(false);
        setErrorMessage('');
    };
    const timeAlreadyExist = (startTime, editingChapterIndex) => {
        return chapters.some((chapter, index) => chapter.startTime === startTime &&
            (editingChapterIndex === undefined || index !== editingChapterIndex));
    };
    const validateTime = (startTime, mediaDuration, editingChapterIndex) => {
        if (isNaN(startTime) || startTime >= mediaDuration) {
            return {
                isValid: false,
                errorMessage: t(TIME_ERROR_VALIDATOR_MESSAGE.TIME_NOT_EXISTS),
            };
        }
        if (timeAlreadyExist(startTime, editingChapterIndex)) {
            return {
                isValid: false,
                errorMessage: t(TIME_ERROR_VALIDATOR_MESSAGE.TIME_ALREADY_EXISTS),
            };
        }
        return { isValid: true };
    };
    const sortAndUpdateChaptersEndTime = (chapters, mediaDuration) => {
        if (chapters.length === 0)
            return [];
        const sortedChapters = [...chapters].sort((a, b) => a.startTime - b.startTime);
        return sortedChapters.map((chapter, index) => ({
            ...chapter,
            endTime: index < sortedChapters.length - 1
                ? sortedChapters[index + 1].startTime
                : mediaDuration,
        }));
    };
    const deleteChapter = (startTime) => {
        updateChapters(sortAndUpdateChaptersEndTime(chapters.filter((chapter) => chapter.startTime !== startTime), mediaDuration));
        trackingEventDebounced({
            action: Actions.CLICK_DELETE_CHAPTER,
        });
    };
    const deleteAllChapters = () => {
        updateChapters([]);
        trackingEventDebounced({
            action: Actions.CLICK_CHAPTER_DELETE_ALL,
        });
    };
    const saveChapter = (startTime, title) => {
        const result = validateTime(startTime, mediaDuration, editingChapterIndex);
        if (!result.isValid) {
            setErrorMessage(result.errorMessage);
            return;
        }
        const isItANewChapter = editingChapterIndex === null;
        updateChapters((prevChapters) => {
            let updatedChapters;
            if (isItANewChapter) {
                updatedChapters = [
                    ...prevChapters,
                    {
                        startTime,
                        endTime: mediaDuration,
                        title,
                    },
                ];
            }
            else {
                updatedChapters = prevChapters.map((chapter, index) => index === editingChapterIndex
                    ? { ...chapter, startTime, title }
                    : chapter);
            }
            return sortAndUpdateChaptersEndTime(updatedChapters, mediaDuration);
        });
        closeChapterForm();
        trackingEventDebounced({
            action: Actions.CLICK_CHAPTER_TIMEBAR_SAVE,
        });
    };
    return {
        isChapterBeingCreated,
        editingChapterIndex,
        errorMessage,
        isChapterBeingEdited,
        startEditingChapter,
        startAddingNewChapter,
        closeChapterForm,
        deleteChapter,
        deleteAllChapters,
        saveChapter,
    };
}
