import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useRef } from 'react';
import { useSelectEventListeners } from '../hooks/useSelectEventListeners';
export function Select(props) {
    const selectRef = useRef(null);
    useSelectEventListeners(selectRef, {
        onChange: props.onChange,
        onFocus: props.onFocus,
        onOpen: props.onOpen,
        onClose: props.onClose,
    });
    useEffect(function removeHistorySuggestion() {
        if (selectRef.current) {
            selectRef.current.shadowRoot
                ?.querySelector('.hot-form__input')
                ?.setAttribute?.('autocomplete', 'off');
        }
    }, []);
    return (_jsx("hot-select", { ref: selectRef, id: props.id, "data-testid": 'hot-select-element', class: props.className, ...(props.invalid && { invalid: true }), ...(props.loading && { loading: true }), ...(props.disabled && { disabled: true }), "text-no-results": props.textNoResults, placeholder: props.placeholder, children: props.children }));
}
