import { ChaptersStatus, } from 'src/infrastructure/endpoints/chapters/interfaces';
import { useMutation, useQuery, useQueryClient, } from 'src/application/hooks/useRequest';
import { QueryKeys } from 'src/infrastructure/libs/ReactQuery/enums';
import { Toast } from 'src/ui/cosmos/Toast';
import { useTranslation } from 'react-i18next';
import ChaptersService from '../../services/ChaptersService';
export const CHAPTER_STATUS_INTERVAL = 10 * 1000; // 10 seconds
export function useMediaChapters({ mediaCode, mediaLanguage, }) {
    const queryClient = useQueryClient();
    const { t } = useTranslation();
    const mediaChaptersStatus = useQuery({
        queryKey: [QueryKeys.MEDIA_CHAPTERS_STATUS, mediaCode, mediaLanguage],
        queryFn: () => ChaptersService.getChapterGenerationStatusByMedia({
            mediaCode,
            mediaLanguage,
        }),
        refetchInterval: (data) => data?.status === ChaptersStatus.QUEUED ||
            data?.status === ChaptersStatus.PROCESSING
            ? CHAPTER_STATUS_INTERVAL
            : false,
        onSuccess: (data) => {
            if (data?.status !== ChaptersStatus.QUEUED &&
                data?.status !== ChaptersStatus.PROCESSING) {
                queryClient.invalidateQueries({ queryKey: [QueryKeys.MEDIA_CHAPTERS] });
            }
        },
    });
    const mediaChaptersQuery = useQuery({
        queryKey: [QueryKeys.MEDIA_CHAPTERS, mediaCode, mediaLanguage],
        queryFn: () => ChaptersService.getMediaChapters({ mediaCode, mediaLanguage }),
        enabled: !!mediaChaptersStatus?.data,
    });
    const createOrUpdateMediaChapters = useMutation({
        mutationFn: async ({ chapters }) => {
            return await ChaptersService.createOrUpdateMediaChapters({
                mediaCode,
                mediaLanguage,
                chapters,
            });
        },
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: [QueryKeys.MEDIA_CHAPTERS, mediaCode, mediaLanguage],
            });
            Toast.success({
                message: t('library.settings.chapters.toasts.chapters_saved'),
            });
        },
    });
    const deleteMediaChapters = useMutation({
        mutationFn: async () => {
            return await ChaptersService.deleteMediaChapters({
                mediaCode,
                mediaLanguage,
            });
        },
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: [QueryKeys.MEDIA_CHAPTERS, mediaCode, mediaLanguage],
            });
            Toast.success({
                message: t('library.settings.chapters.toasts.chapters_deleted'),
            });
        },
    });
    return {
        mediaChaptersQuery,
        createOrUpdateMediaChapters,
        deleteMediaChapters,
        mediaChaptersStatus,
    };
}
