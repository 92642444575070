export var Actions;
(function (Actions) {
    Actions["CLICK_CHAPTER_ADD_NEW"] = "click_chapter_add_new";
    Actions["CLICK_CHAPTER_DELETE_ALL"] = "click_chapter_delete_all";
    Actions["CLICK_CHAPTER_ERROR_MODAL_RETRY"] = "click_chapter_error_modal_retry";
    Actions["CLICK_CHAPTER_GENERATE_AI"] = "click_chapter_generate_AI";
    Actions["CLICK_CHAPTER_PAGE_SAVE"] = "click_chapter_page_save";
    Actions["CLICK_CHAPTER_SIDEMENU"] = "click_chapter_sidemenu";
    Actions["CLICK_CHAPTER_TIMEBAR_EDIT"] = "click_chapter_timebar_edit";
    Actions["CLICK_CHAPTER_TIMEBAR_SAVE"] = "click_chapter_timebar_save";
    Actions["CLICK_DELETE_CHAPTER"] = "click_delete_chapter";
    Actions["CLOSE_CHAPTER_ERROR_MODAL"] = "close_chapter_error_modal";
    Actions["TRY_GENERATE_CHAPTER"] = "try_generate_chapter";
    Actions["VIEW_CHAPTER_ERROR_MODAL"] = "view_chapter_error_modal";
    Actions["VIEW_CHAPTER_PAGE"] = "view_chapter_page";
})(Actions || (Actions = {}));
