// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MZcc0Ztss1DiA7YPhTaQ{display:flex;gap:.5rem;flex-direction:column;width:100%}.MZcc0Ztss1DiA7YPhTaQ .xgKDwzjKXB49fwZd7t4w{width:100%;height:3rem}.MZcc0Ztss1DiA7YPhTaQ .PHKcJViAZAR2bFmxQM9G{display:flex;gap:.5rem;justify-content:flex-end}.MZcc0Ztss1DiA7YPhTaQ .PHKcJViAZAR2bFmxQM9G .UtUZPRR68UplEuJaddK2{width:6.25rem;height:1.75rem}`, "",{"version":3,"sources":["webpack://./src/domain/Media/modules/Chapters/components/ChapterList/ChapterListLoader/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,SAAA,CACA,qBAAA,CACA,UAAA,CAEA,4CACE,UAAA,CACA,WAAA,CAGF,4CACE,YAAA,CACA,SAAA,CACA,wBAAA,CAEA,kEACE,aAAA,CACA,cAAA","sourcesContent":[".chapterListLoader {\n  display: flex;\n  gap: 0.5rem;\n  flex-direction: column;\n  width: 100%;\n\n  .chapterLoader {\n    width: 100%;\n    height: 3rem;\n  }\n\n  .buttons {\n    display: flex;\n    gap: 0.5rem;\n    justify-content: flex-end;\n\n    .buttonLoader {\n      width: 6.25rem;\n      height: 1.75rem;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"chapterListLoader": `MZcc0Ztss1DiA7YPhTaQ`,
	"chapterLoader": `xgKDwzjKXB49fwZd7t4w`,
	"buttons": `PHKcJViAZAR2bFmxQM9G`,
	"buttonLoader": `UtUZPRR68UplEuJaddK2`
};
export default ___CSS_LOADER_EXPORT___;
