import { getChapterGenerationStatus, generateChaptersWithAI, retryFailedChapters, getMediaChapters, createOrUpdateMediaChapters, deleteMediaChapters, getChapterGenerationStatusByMedia, } from 'src/infrastructure/endpoints/chapters';
class ChaptersService {
    async getChapterGenerationStatus() {
        return getChapterGenerationStatus();
    }
    async generateChaptersWithAI({ mediaCodes, language, }) {
        return generateChaptersWithAI({ mediaCodes, language });
    }
    async retryFailedChapters(id) {
        return retryFailedChapters(id);
    }
    async getMediaChapters({ mediaCode, mediaLanguage }) {
        return getMediaChapters({ mediaCode, mediaLanguage });
    }
    async createOrUpdateMediaChapters({ mediaCode, mediaLanguage, chapters, }) {
        return createOrUpdateMediaChapters({ mediaCode, mediaLanguage, chapters });
    }
    async deleteMediaChapters({ mediaCode, mediaLanguage, }) {
        return deleteMediaChapters({ mediaCode, mediaLanguage });
    }
    async getChapterGenerationStatusByMedia({ mediaCode, mediaLanguage, }) {
        return getChapterGenerationStatusByMedia({
            mediaCode,
            mediaLanguage,
        });
    }
}
export default new ChaptersService();
