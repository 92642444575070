import { useCallback } from 'react';
export const usePlayerPostMessage = (playerRef) => {
    const sendMessage = useCallback((data) => {
        playerRef.current?.contentWindow?.postMessage(JSON.stringify(data), '*'); // NOSONAR
    }, [playerRef]);
    const setComponentsVisibility = useCallback((preferences) => {
        const data = {
            event: 'SET_COMPONENTS_VISIBILITY',
            payload: preferences,
        };
        sendMessage(data);
    }, [sendMessage]);
    const setPlayerTimeInSeconds = useCallback((time) => {
        const data = {
            event: 'SET_PLAYER_TIME',
            payload: time,
        };
        sendMessage(data);
    }, [sendMessage]);
    const updatePlayerChapters = useCallback((chapters) => {
        const data = {
            event: 'UPDATE_CHAPTERS',
            payload: chapters,
        };
        sendMessage(data);
    }, [sendMessage]);
    return {
        setComponentsVisibility,
        setPlayerTimeInSeconds,
        updatePlayerChapters,
    };
};
