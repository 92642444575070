import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button } from 'src/ui/cosmosWeb/Button';
import { useState } from 'react';
import { timeStringToSeconds } from 'src/utils/time';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';
const defaultFormState = { title: '', time: '00:00:00' };
export function ChapterForm({ onSave, onCancel, initialState = defaultFormState, errorMessage, disableInputTime = false, ...rest }) {
    const [time, setTime] = useState(initialState.time);
    const [title, setTitle] = useState(initialState.title);
    const { t } = useTranslation();
    const handleSave = () => {
        onSave(timeStringToSeconds(time), title);
    };
    const aChapterIsBeingUpdated = initialState !== defaultFormState;
    return (_jsxs("div", { ...rest, className: styles.chapterForm, children: [_jsxs("div", { className: styles.inputs, children: [_jsx("input", { readOnly: disableInputTime, "data-testid": 'input-chapter-time', value: time, onChange: (e) => setTime(e.target.value), type: 'time', id: 'time', step: 3 }), _jsx("input", { value: title, "data-testid": 'input-chapter-title', onChange: (e) => setTitle(e.target.value), required: true, type: 'text', id: 'title', placeholder: t('library.settings.chapters.placeholders.chapter_name'), maxLength: 40 })] }), errorMessage && (_jsx("span", { className: styles.errorMessage, children: errorMessage })), _jsxs("div", { className: styles.buttons, children: [_jsx(Button, { "data-testid": 'chapter-form-cancel-btn', onClick: onCancel, size: 'small', variation: 'secondary', children: t('library.settings.chapters.buttons.cancel') }), _jsx(Button, { "data-testid": 'chapter-form-save-btn', disabled: !title, onClick: handleSave, size: 'small', children: t(`library.settings.chapters.buttons.${aChapterIsBeingUpdated ? 'update' : 'save'}`) })] })] }));
}
