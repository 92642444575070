import { convertTime } from '../convertTime';
export function secondsToTimeString(seconds) {
    return convertTime(seconds)
        .map((unit) => unit.toString().padStart(2, '0'))
        .join(':');
}
export function timeStringToSeconds(timeString) {
    const [hours, minutes, seconds] = timeString.split(':').map(Number);
    return hours * 3600 + minutes * 60 + seconds;
}
